export default [
    {
        title: 'Back',
        route: 'Hotsport-back',
        icon: 'ChevronLeftIcon',
    },
    {
        header: 'Hotsport',
    },

    {
        title: 'Hotsport',
        route: 'hotspot',
        icon: 'HardDriveIcon',
    },
    {
        title: 'Add Hotsport',
        route: 'hotspot-add',
        icon: 'PlusIcon',
    },
   

]
